<template>
  <v-row>
    <!-- basic -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Días no Laborables</v-card-title>
        <DataTableCalendar></DataTableCalendar>
      </v-card>
    </v-col>

   
  </v-row>
</template>

<script>
import DataTableCalendar from './datatable/datatableCalendar.vue'

export default {
  components: {

    DataTableCalendar,

  },
  setup() {
    return {}
  },
}
</script>
