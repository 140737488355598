<template>
  <v-container fluid>
    <v-form
      ref="form"
    >
      <v-data-table
        :headers="headers"
        :items="data"
        item-key="id"
        sort-by="name"
        :search="buscar"
        :header-props="headerProps"
        :footer-props="footerProps"
        :loading="loadingHolidays"
        loading-text="Cargando días no laborales..."
        no-data-text="No hay registros para mostrar."
        no-results-text="No se encontraron registros en la búsqueda"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="date"
                  label="Fecha (dd/mm/aaaa)"
                  :rules="[Validaciones.requerido]"
                  :append-icon="icons.mdiCalendarText"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="description"
                  label="Descripción"
                  :rules="[Validaciones.requerido]"
                  :append-icon="icons.mdiTextBoxEditOutline"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn
                  elevation="20"
                  x-small
                  fab
                  color="info"
                  dark
                  class="mx-2"
                  @click="addHoliday()"
                >
                  <v-icon>
                    {{ icons.mdiTableLargePlus }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{ item }">
          <!-- <v-icon
        size="18"
        class="me-3"
      >
        {{ icons.mdiPencil }}
      </v-icon> -->

          <v-tooltip
            bottom
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="error"
                size="18"
                v-bind="attrs"
                class="me-3"
                v-on="on"
                @click="deleteItem(item)"
              >
                {{ icons.mdiDeleteEmpty }}
              </v-icon>
            </template>
            <span>Eliminar Feriado</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-form>
    <v-dialog
      v-model="dialogDelete"
      max-width="800px"
    >
      <v-card>
        <v-card-title
          v-if="holidayDelete"
          class="text-h5"
        >
          ¿Está seguro que desea eliminar el feriado?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  mdiDeleteEmpty, mdiTableLargePlus, mdiTextBoxEditOutline, mdiCalendarText,
} from '@mdi/js'
import axios from 'axios'

export default {

  data: () => ({
    date: '',
    description: '',
    loadingHolidays: false,
    dialogDelete: false,
    icons: {
      mdiDeleteEmpty,
      mdiTableLargePlus,
      mdiTextBoxEditOutline,
      mdiCalendarText,

    },
    Validaciones: {
      requerido: v => !!v || 'Campo Requerido',
    },
    headers: [
      {
        text: 'Id',
        align: 'start',
        sortable: true,
        value: 'id',
      },
      { text: 'Fecha', value: 'date' },
      // eslint-disable-next-line no-useless-concat
      { text: 'Descripción', value: 'description' },
      { text: 'Acciones', value: 'actions', sortable: false },

    ],
    headerProps: {
      sortByText: 'Ordenar por',
    },
    footerProps: {
      itemsPerPageText: 'Registros por página',
      pageText: '{0}-{1} de {2}',
      itemsPerPageOptions: [5, 10, 20, 50, 100, { text: 'Todos', value: -1 }],
    },
    data: [],
    buscar: '',
    holidayDelete: null,
  }),

  computed: {

    computedDateFormatted() {
      return this.formatDate(this.date)
    },

  },

  mounted() {
    this.getHolidays()
  },
  methods: {

    deleteItem(item) {
      this.holidayDelete = item
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.deleteHolidayConfirm()
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.holidayDelete = null
    },
    async getHolidays() {
      this.loadingHolidays = true
      this.url = `${this.$store.getters.urlBase}/api/Setting/GetHolidays`
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        url: this.url,
      }
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
      // console.log(respuesta.data);
        this.data = respuesta.data
        this.loadingHolidays = false

      // this.getListarDiasMantenimiento();
      } else {
      // this.colorSnack = "red";
      // this.mensajeSnack = respuesta.data;
      // this.Showsnackbar = true;
        this.loadingHolidays = false
      }
    },

    async deleteHolidayConfirm() {
  

      this.url = `${this.$store.getters.urlBase}/api/Setting/DeleteHoliday`
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
          'content-type': 'application/json',
        },
        params: { idHoliday: parseInt(this.holidayDelete.id) },
        url: this.url,
      }

      await axios(options)
        .then(response => {
          //    this.isSaving = false
          this.$store.dispatch('showSnack', {
            text: 'Feriado Eliminado Correctamente',
            color: 'success',
            timeout: 2500,
          })

          this.getHolidays()
        })
        .catch(e => {
          if (e.response) {
            if (e.response.data) { this.mensajeSnack = e.response.data } else { this.mensajeSnack = 'Error en la operación' }
          } else {
            this.mensajeSnack = 'Error de conexion'
          }
          this.$store.dispatch('showSnack', {
            text: this.mensajeSnack,
            color: 'error',
            timeout: 2500,

          })

          //  this.isSaving = false
        })
    },
    ConvertirStringFecha(dateString) {
      const split = dateString.split('/')

      return `${split[2]}-${split[1]}-${split[0]}`
    },
    async addHoliday() {
      if (this.$refs.form.validate()) {
      //  this.isSaving = true
        this.url = `${this.$store.getters.urlBase}/api/Setting/AddNewHoliday`
        const options = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
            'content-type': 'application/json',
          },
          params: { description: this.description, date: this.ConvertirStringFecha(this.date) },
          url: this.url,
        }

        await axios(options)
          .then(response => {
            //    this.isSaving = false
            this.$store.dispatch('showSnack', {
              text: 'Feriado Ingresado Correctamente',
              color: 'success',
              timeout: 2500,
            })

            this.getHolidays()
            this.description = null
            this.date = null
          })
          .catch(e => {
            if (e.response) {
              if (e.response.data) { this.mensajeSnack = e.response.data } else { this.mensajeSnack = 'Error en la operación' }
            } else {
              this.mensajeSnack = 'Error de conexion'
            }
            this.$store.dispatch('showSnack', {
              text: this.mensajeSnack,
              color: 'error',
              timeout: 2500,

            })

          //  this.isSaving = false
          })
      }
    },
  },
}
</script>
